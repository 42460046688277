// all styles here
import "./src/assets/css/bootstrap.min.css"
import "./src/assets/css/animate.min.css"
import "./src/assets/css/flaticon.css"
import "./src/assets/css/style.css"
import "./src/assets/css/responsive.css"
import "./src/assets/css/styleScss.scss"

export const onRouteUpdate = ({ location }) => {
// Check if the path includes "getap"
if (location.pathname.includes("gemai-convos")) {
    console.log('Path includes "getap". Loading CSS.');
    // Load the additional CSS file
    const link = document.createElement("link");
    link.href = "/components/GemAI/assets/css/General.css"; // Replace with the actual path to your CSS file
    link.rel = "stylesheet";

    document.head.appendChild(link);
}
};